import React from 'react'

type Props = {
  id: string,
  label: string,
  max: string,
  min: string,
  value: number,
  onChange: (value: number) => void,
}

function Range({ id, label, max, min, value, onChange }: Props) {
  return (
    <label htmlFor={id} className="form-label">
      {label}
      <input
        id={id}
        className="form-range"
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={event => onChange(Number(event.target.value))}
      />
    </label>
  )
}

export default Range
