import React from 'react'

import Background from './pieces/Background'
import Mouth from './pieces/Mouth'
import Eyes from './pieces/Eyes'

import { Values } from '../../../types/Values'

type Props = {
  styles: Values | undefined,
  id: string,
}

function Face({ styles, id }: Props) {
  const color = styles?.face.color
  const image = styles?.face.image
  const width = styles?.face.width
  const height = styles?.face.height
  const rotate = styles?.face.rotate
  const [transform, setTransform] = React.useState('')
  React.useEffect(() => {
    setTransform(`scale(${width}, ${height}) rotate(${rotate})`)
  }, [rotate, width, height])

  return(
    <div id={id} className="face">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100%" transform={transform}>
        <Background color={color} image={image} />
        <Eyes style={styles?.eyes} />
        <Mouth style={styles?.mouth} />
      </svg>
    </div>
  )
}

export default Face
