import React from 'react'

function Mouth1() {
  return (
    <g>
			<g>
				<g>
					<path fill="#000" d="M48,36.2c-14.3,0-27.4-6.6-35.9-18l5.5-4.1c7.2,9.7,18.3,15.3,30.4,15.3c12.6,0,24.4-6.3,31.5-16.7l5.7,3.8
						C76.8,28.9,62.9,36.2,48,36.2z"/>
				</g>
				<g>
					<path fill="#000" d="M86.4,22c-6.1,0-11.1-5-11.1-11.1c0-3,1.1-5.7,3.2-7.8c1.3-1.3,3.5-1.3,4.8,0c1.3,1.3,1.3,3.5,0,4.8
						c-0.8,0.8-1.2,1.9-1.2,3c0,2.3,1.9,4.2,4.2,4.2c1.1,0,2.2-0.4,3-1.2c1.3-1.3,3.5-1.3,4.8,0c1.3,1.3,1.3,3.5,0,4.8
						C92.1,20.9,89.3,22,86.4,22z"/>
				</g>
				<g>
					<path fill="#000" d="M11.1,24.5c-3,0-5.7-1.1-7.8-3.2C2,20,2,17.8,3.3,16.5c1.3-1.3,3.5-1.3,4.8,0c0.8,0.8,1.9,1.2,3,1.2
						c2.3,0,4.2-1.9,4.2-4.2c0-1.1-0.4-2.2-1.2-3c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0c2.1,2.1,3.2,4.9,3.2,7.8
						C22.2,19.6,17.2,24.5,11.1,24.5z"/>
				</g>
				<g>
					<g>
						<circle fill="#000" cx="7.3" cy="9.5" r="1.5"/>
					</g>
					<g>
						<circle fill="#000" cx="8.7" cy="3.4" r="1.5"/>
					</g>
					<g>
						<circle fill="#000" cx="2.1" cy="6.4" r="1.5"/>
					</g>
				</g>
				<g>
					<g>
						<ellipse transform="matrix(0.998 -6.386342e-02 6.386342e-02 0.998 -0.2653 5.7451)" fill="#000" cx="89.7" cy="7" rx="1.5" ry="1.5"/>
					</g>
					<g>
						<ellipse transform="matrix(0.998 -6.386342e-02 6.386342e-02 0.998 -0.289 6.1413)" fill="#000" cx="95.9" cy="7.6" rx="1.5" ry="1.5"/>
					</g>
					<g>
						<ellipse transform="matrix(0.998 -6.386340e-02 6.386340e-02 0.998 9.651964e-02 5.8809)" fill="#000" cx="92" cy="1.4" rx="1.5" ry="1.5"/>
					</g>
				</g>
			</g>
			<g>
				<path fill="#000" d="M58.1,18c-1.9,0-3.4-1.5-3.4-3.4c0-1.1-2.3-2.8-5.7-2.8s-5.7,1.7-5.7,2.8c0,1.9-1.5,3.4-3.4,3.4
					s-3.4-1.5-3.4-3.4C36.5,9.2,42,5,49,5s12.5,4.2,12.5,9.6C61.5,16.5,60,18,58.1,18z"/>
			</g>
		</g>
  )
}

export default Mouth1