import React from 'react'

function Carita() {
  // Get each eye
  const leftEye = React.useRef<SVGGElement>(null)
  const rightEye = React.useRef<SVGGElement>(null)

  function moveEyes(event: MouseEvent, leftEye: SVGGElement, leftIris: SVGGElement, rightEye: SVGGElement, rightIris: SVGGElement) {
    // Get the eyes dimensions and positions
    const leftEyeSocketBounds = leftEye.getElementsByTagName('path')[0].getBoundingClientRect()
    const rightEyeSocketBounds = rightEye.getElementsByTagName('path')[0].getBoundingClientRect()
    const eyeWidth = leftEyeSocketBounds.width
    const eyeHeight = leftEyeSocketBounds.height
    const leftMinX = leftEyeSocketBounds.left
    const leftMaxX = leftEyeSocketBounds.right
    const leftMaxY = leftEyeSocketBounds.bottom
    const leftMinY = leftEyeSocketBounds.top
    const rightMinX = rightEyeSocketBounds.left
    const rightMaxX = rightEyeSocketBounds.right
    const rightMaxY = rightEyeSocketBounds.bottom
    const rightMinY = rightEyeSocketBounds.top

    // Max and min positions in units + the height and the width in units
    const minX = 13
    const maxX = 17
    const minY = 18
    const maxY = 38
    const widthUnits = maxX - minX
    const heightUnits = maxY - minY

    // Position of the mouse pointer
    const x = event.clientX
    const y = event.clientY

    // Position of the mouse pointer relative to the inside of each eye
    const leftX = minX + ((x - leftMinX) * widthUnits) / eyeWidth
    const leftY = minY + ((y - leftMinY) * heightUnits) / eyeHeight
    const rightX = minX + ((x - rightMinX) * widthUnits) / eyeWidth
    const rightY = minY + ((y - rightMinY) * heightUnits) / eyeHeight

    // Check to see if the mouse pointer is inside or outside each eye
    const lcx = (x < leftMinX ? minX : (x > leftMaxX ? maxX : leftX))
    const lcy = (y < leftMinY ? minY : (y > leftMaxY ? maxY : leftY))
    const rcx = (x < rightMinX ? minX : (x > rightMaxX ? maxX : rightX))
    const rcy = (y < rightMinY ? minY : (y > rightMaxY ? maxY : rightY))

    // Adjust the position of each iris
    leftIris.setAttribute('cx', lcx.toString())
    leftIris.setAttribute('cy', lcy.toString())
    rightIris.setAttribute('cx', rcx.toString())
    rightIris.setAttribute('cy', rcy.toString())
  }

  function createIris(eye: SVGGElement): SVGCircleElement {
    // Create a white circle and attach it to the eye
    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
    circle.setAttribute('cx', '15')
    circle.setAttribute('cy', '28')
    circle.setAttribute('r', '2')
    circle.setAttribute('fill', '#fff')
    eye.appendChild(circle)
    return circle
  }

  React.useEffect(() => {
    if ((!leftEye) || (!rightEye)) return () => {}
    // Create an iris for each eye
    const leftEyeElement = leftEye.current
    const rightEyeElement = rightEye.current
    if ((!leftEyeElement) || (!rightEyeElement)) return () => {}
    const leftIris = createIris(leftEyeElement)
    const rightIris = createIris(rightEyeElement)

    // Set the mouse tracker
    window.addEventListener(
      'mousemove',
      event => moveEyes(event, leftEyeElement, leftIris, rightEyeElement, rightIris),
      true,
    )
    // Cleanup function
    return () => {
      window.removeEventListener(
        'mousemove',
        event => moveEyes(event, leftEyeElement, leftIris, rightEyeElement, rightIris),
        true,
      )
    }
  }, [leftEye, rightEye])

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100%" preserveAspectRatio="none" transform="scale(1, 1) rotate(0)">
      <g>
        <defs>
          <pattern id="bg-image" patternUnits="userSpaceOnUse" width="100" height="100">
            <image preserveAspectRatio="xMidYMid slice" width="100" height="100"/>
          </pattern>
        </defs>
        <circle cx="50" cy="50" r="45" fill="#fbbb25"/>
      </g>
      <g transform="translate(35, 13)">
        <g transform="rotate(0 14.848748564720154 20.600921630859375)">
          <g ref={leftEye} transform="translate(-14) matrix(0.63, 0, 0, 0.63, 5.499427471160889, 7.624452514648437)">
            <g fill="#000" transform="matrix(1.33333 0 0 -1.33333 -475.42 389.42)">
              <path d="M371.87 271.04c0-5.45-1.86-9.88-4.16-9.88s-4.17 4.43-4.17 9.88c0 5.46 1.87 9.88 4.17 9.88s4.16-4.42 4.16-9.88"/>
              <path d="M376.46 278.54a2.39 2.39 0 0 0-2.38 2.38 6.38 6.38 0 0 1-12.75 0 2.39 2.39 0 1 0-4.77 0c0 6.15 5 11.15 11.14 11.15 6.15 0 11.15-5 11.15-11.15a2.39 2.39 0 0 0-2.39-2.38"/>
            </g>
          </g>
          <g ref={rightEye} transform="translate(14) matrix(0.63, 0, 0, 0.63, 5.499427471160889, 7.624452514648437)">
            <g fill="#000" transform="matrix(1.33333 0 0 -1.33333 -475.42 389.42)">
              <path d="M371.87 271.04c0-5.45-1.86-9.88-4.16-9.88s-4.17 4.43-4.17 9.88c0 5.46 1.87 9.88 4.17 9.88s4.16-4.42 4.16-9.88"/>
              <path d="M376.46 278.54a2.39 2.39 0 0 0-2.38 2.38 6.38 6.38 0 0 1-12.75 0 2.39 2.39 0 1 0-4.77 0c0 6.15 5 11.15 11.14 11.15 6.15 0 11.15-5 11.15-11.15a2.39 2.39 0 0 0-2.39-2.38"/>
            </g>
          </g>
        </g>
      </g>
      <svg x="2" y="35">
        <g transform="rotate(0 48.19362926483154 32.8556444644928) matrix(0.63, 0, 0, 0.63, 17.835931129455567, 12.157512855529784)">
          <g transform="matrix(1.33333 0 0 -1.33333 -420.47 348)">
            <path fill="#fff" d="m379.55 243.16-5.05-6.34-1.42 6.2a1.59 1.59 0 0 1-2.82.6l-3.35-4.4-3.84 4.48c-.3.35-.74.56-1.2.56h-.03a1.6 1.6 0 0 1-1.21-.6l-3.64-4.5-3.78 4.36c-.08.12-.15.25-.27.35-.12.1-.26.16-.4.22-.15.07-.3.1-.45.12a.78.78 0 0 1-.44 0c-.16-.01-.3-.05-.45-.11v-.01c-.15-.06-.28-.12-.4-.22s-.19-.23-.27-.35l-3.78-4.35-3.64 4.5c-.3.36-.75.58-1.22.59-.4 0-.92-.2-1.23-.56l-3.83-4.48-3.36 4.4a1.6 1.6 0 0 1-2.81-.6l-1.42-6.2-5.45 6.84c-.16.2-.37.34-.6.44l-3.04 11.32c-.08.28 0 .51.24.68.12.09.25.13.37.13s.24-.04.37-.13a53.68 53.68 0 0 1 60.74 0c.25.17.5.17.74 0s.32-.4.24-.68z"/>
            <path fill="#d66525" d="M368.44 221.05a33.78 33.78 0 0 0-33.88 0 17.62 17.62 0 0 0-8.02 10.64l-1.39 5.15 3.66-4.58a1.6 1.6 0 0 1 2.79.63L333 239l2.5-3.27a1.59 1.59 0 0 1 2.47-.07l3.87 4.52 3.63-4.49a1.59 1.59 0 0 1 2.43-.04l3.97 4.56 3.96-4.56a1.59 1.59 0 0 1 2.44.04l3.63 4.49 3.87-4.52a1.59 1.59 0 0 1 2.47.07l2.5 3.27 1.4-6.1a1.59 1.59 0 0 1 2.8-.64l2.53 3.19-1.01-3.76a17.62 17.62 0 0 0-8.02-10.64"/>
            <path fill="#000" d="m379.55 243.16-5.05-6.34-1.42 6.2a1.59 1.59 0 0 1-2.82.6l-3.35-4.4-3.84 4.48c-.3.35-.74.56-1.2.56h-.03a1.6 1.6 0 0 1-1.21-.6l-3.64-4.5-3.78 4.36c-.08.12-.15.25-.27.35-.12.1-.26.16-.4.22-.15.07-.3.1-.45.12a.78.78 0 0 1-.44 0c-.16-.01-.3-.05-.45-.11v-.01c-.15-.06-.28-.12-.4-.22s-.19-.23-.27-.35l-3.78-4.35-3.64 4.5c-.3.36-.75.58-1.22.59-.4 0-.92-.2-1.23-.56l-3.83-4.48-3.36 4.4a1.6 1.6 0 0 1-2.81-.6l-1.42-6.2-5.45 6.84c-.16.2-.37.34-.6.44l-3.04 11.32c-.08.28 0 .51.24.68.12.09.25.13.37.13s.24-.04.37-.13a53.68 53.68 0 0 1 60.74 0c.25.17.5.17.74 0s.32-.4.24-.68zm-11.11-22.1a33.78 33.78 0 0 0-33.88 0 17.62 17.62 0 0 0-8.02 10.62l-1.39 5.16 3.66-4.58a1.6 1.6 0 0 1 2.79.63L333 239l2.5-3.27c.28-.38.73-.61 1.21-.63.44-.01.94.2 1.26.56l3.87 4.52 3.63-4.49a1.59 1.59 0 0 1 2.43-.04l3.97 4.56 3.96-4.56a1.6 1.6 0 0 1 2.44.04l3.63 4.49 3.87-4.52c.32-.37.82-.57 1.26-.56.48.02.92.25 1.22.63l2.49 3.27 1.4-6.11a1.59 1.59 0 0 1 2.8-.63l2.53 3.19-1.01-3.76a17.62 17.62 0 0 0-8.02-10.64M385.36 260a5.36 5.36 0 0 1-6.2.03 48.9 48.9 0 0 0-55.32 0 5.36 5.36 0 0 1-6.2-.03 5.36 5.36 0 0 1-2.1-5.83l6.4-23.73a22.42 22.42 0 0 1 10.22-13.51 38.47 38.47 0 0 1 38.68 0 22.43 22.43 0 0 1 10.22 13.51l6.4 23.74c.6 2.21-.23 4.5-2.1 5.82"/>
          </g>
        </g>
      </svg>
    </svg>
  )
}

export default Carita
