import React from 'react'

type Option = {
  value: string,
  label: string,
}

type Props = {
  id: string,
  label: string,
  value: string,
  options: Array<Option>,
  onChange: (value: string) => void,
}

function Select({ id, label, value, options, onChange }: Props) {
  return (
    <div>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <select
        id={id}
        className="form-select mb-3"
        value={value}
        onChange={event => onChange(event.target.value)}
      >
        {
          options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            )
          })
        }
      </select>
    </div>
  )
}

export default Select
