import React from 'react'

import Modal from 'react-bootstrap/Modal'

import Title from '../../components/Title'
import Loading from '../../components/Loading'

import FaceControls from './components/FaceControls'
import Face from './components/Face'

import { Values } from '../../types/Values'

type Props = {
  theme: string,
}

function Caritas({ theme }: Props) {
  const [modalOpen, setModalOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const defaultValues = {
    face: {
      width: 100,
      height: 100,
      rotate: 0,
      color: '#fbbb25',
      image: undefined,
    },
    eyes: {
      x: 35,
      y: 13,
      size: 63,
      separation: 14,
      rotate: 0,
      option: '1',
      left: {
        color: '#000',
      },
      right: {
        color: '#000',
      },
    },
    mouth: {
      x: 2,
      y: 35,
      size: 63,
      rotate: 0,
      option: '1',
    },
  }

  const [values, setValues] = React.useState<Values>(defaultValues)
  const [styles, setStyles] = React.useState<Values>()

  React.useEffect(() => {
    setStyles({
      face: values.face,
      eyes: values.eyes,
      mouth: values.mouth,
    })
  }, [values])

  // React.useEffect(() => {
  //   console.log(modalOpen)
  // }, [modalOpen])

  function download(href: string, name: string){
    const link = document.createElement('a')
    link.href = href
    link.download = name
    link.click()
    link.remove()
    setLoading(false)
  }

  function downloadSvg() {
    setModalOpen(false)
    setLoading(true)
    const face = (document.getElementById('face') as HTMLElement).innerHTML
    const svg = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(face)}`
    download(svg, 'carita.svg')
  }

  function downloadRaster(format: string) {
    setModalOpen(false)
    setLoading(true)
    const size = 512
    const face = (document.querySelector('#face svg') as SVGElement).cloneNode(true) as HTMLElement
    face.setAttribute('width', size.toString())
    face.setAttribute('height', size.toString())
    const xml = new XMLSerializer().serializeToString(face)
    const blob = new Blob([xml], { type: 'image/svg+xml;charset=utf-8' })
    const blobURL = URL.createObjectURL(blob)
    const image = new Image()
    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = size
      canvas.height = size
      const context = canvas.getContext('2d') as CanvasRenderingContext2D
      context.drawImage(image, 0, 0, canvas.width, canvas.height)
      const raster = canvas.toDataURL(`image/${format}`, 1)
      download(raster, `carita.${format}`)
    }
    image.src = blobURL
  }

  return(
    <div className="container my-4">
      <div className="row h-100">
        <div className="col-12">
          <Title title="Carita Showtime Generator" />
        </div>
        <div className="col-md-6">
          <Face id="face" styles={styles} />
        </div>
        <div className="col-md-5 offset-md-1">
          <FaceControls
            values={values}
            onChange={setValues}
            defaults={defaultValues}
            onDownload={() => setModalOpen(true)}
            theme={theme}
          />
        </div>
        <Modal show={modalOpen} onHide={() => setModalOpen(false)} centered contentClassName={theme === 'dark' ? 'bg-dark' : ''}>
          <Modal.Header closeButton closeVariant={theme === 'dark' ? 'white' : undefined}>
            <Modal.Title>
              Formato de descarga
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex justify-stretch">
            <button type="button" className="btn btn-primary me-2 w-100" onClick={downloadSvg}>
              SVG
            </button>
            <button type="button" className="btn btn-primary me-2 w-100" onClick={() => downloadRaster('png')}>
              PNG
            </button>
            <button type="button" className="btn btn-primary w-100" onClick={() => downloadRaster('jpeg')}>
              JPG
            </button>
          </Modal.Body>
        </Modal>
      </div>
      <Loading show={loading} />
    </div>
  )
}

export default Caritas
