import React from 'react'

function Mouth1() {
  return (
    <g>
			<path d="M32.6,36c1.8-11.8,1.7-24.3,13.6-22.8s18.9,4.2,18,14s-1.8,31.4-18,31.4C37.6,58.6,30.8,47.8,32.6,36"/>
			<g>
				<path d="M51.7,8.2c-8,0-12.8-1.8-14.6-5.4c-2.2-4.3,1-8.8,1.3-9.3c0.5-0.7,1.2-1,2.1-1c0,0,0,0,0,0l6.9,0l0.5-8.5
					c0.1-1.4,1.3-2.5,2.7-2.4c1.4,0.1,2.5,1.3,2.4,2.7L52.3-4.9c-0.1,1.3-1.2,2.4-2.5,2.4c0,0,0,0,0,0l-7.8,0c-0.4,0.9-0.8,2.1-0.3,3
					c0.3,0.6,2,2.6,10.1,2.6c1.4,0,2.5,1.1,2.5,2.5S53.1,8.2,51.7,8.2z"/>
			</g>
		</g>
  )
}

export default Mouth1