import React from 'react'

import Title from '../../components/Title'

import { Video } from '../../types/Video'

import videos from '../../data/videos.json'

type Props = {
  theme: string,
}

function Videos({ theme }: Props) {
  const videoElements = (videos as Array<Video>).map((videoItem, index) => {
    return (
      <div key={index} className="col-md-6">
        <div className={`card mb-4 ${theme === 'dark' ? 'text-dark bg-light' : 'text-white bg-dark'}`}>
          <div className="card-img-top">
            <div className="ratio ratio-16x9 mb-2" dangerouslySetInnerHTML={{ __html: videoItem.iframe }}>
            </div>
          </div>
          <h5 className="card-title text-center">
            {videoItem.title}
          </h5>
        </div>
      </div>
    )
  })

  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-12">
          <Title title="Showtivideos" />
        </div>
      </div>
      <div className="row">
        {videoElements}
      </div>
    </div>
  )
}

export default Videos
