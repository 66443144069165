import React from 'react'

function Eyes3() {
  return (
    <g>
			<g>
				<path fill="#fff" d="M29,22.3c0,6.5-5.3,11.8-11.8,11.8S-2.2,28.8-2.2,22.3s12.9-11.8,19.4-11.8S29,15.8,29,22.3z"/>
				<path d="M17.2,37.6c-7.9,0-22.9-6-22.9-15.3S9.3,7,17.2,7c8.4,0,15.3,6.9,15.3,15.3C32.5,30.7,25.6,37.6,17.2,37.6z
					M17.2,14C10.9,14,1.3,19,1.3,22.3s9.7,8.3,15.9,8.3c4.6,0,8.3-3.7,8.3-8.3S21.8,14,17.2,14z"/>
			</g>
			<g>
				<ellipse cx="21.2" cy="22.3" rx="6.1" ry="5.9"/>
			</g>
		</g>
  )
}

export default Eyes3