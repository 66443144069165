import React from 'react'

import Eyes1 from '../../svgs/eyes/Eyes1'
import Eyes2 from '../../svgs/eyes/Eyes2'
import Eyes3 from '../../svgs/eyes/Eyes3'
import Eyes4 from '../../svgs/eyes/Eyes4'

import { calculateRotate, calculateMatrix } from '../../utils'

import { Eyes as EyesType } from '../../../../types/Eyes'

type Props = {
  style: EyesType | undefined,
}

function Eyes({ style }: Props) {
  const x = style?.x
  const y = style?.y
  const rotate = style?.rotate
  const size = style?.size
  const separation = style?.separation
  const left = style?.left
  const right = style?.right
  const option = style?.option
  const eyesGroupElement = React.useRef<SVGGElement>(null)
  const eyesLeftElement = React.useRef<SVGGElement>(null)
  const eyesRightElement = React.useRef<SVGGElement>(null)
  const [eyesGroupElementTransform, setEyesGroupElementTransform] = React.useState('')
  const [eyesLeftElementTransform, setEyesLeftElementTransform] = React.useState('')
  const [eyesRightElementTransform, setEyesRightElementTransform] = React.useState('')

  let eyeElement = null
  if (option === '1') {
    eyeElement = <Eyes1 />
  } else if (option === '2') {
    eyeElement = <Eyes2 />
  } else if (option === '3') {
    eyeElement = <Eyes3 />
  } else if (option === '4') {
    eyeElement = <Eyes4 />
  }

  React.useEffect(() => {
    if ((eyesGroupElement.current) && (rotate !== undefined)) {
      const { x: oX, y: oY, width, height } = eyesGroupElement.current.getBBox()
      setEyesGroupElementTransform(calculateRotate(oX, oY, width, height, rotate))
    }
  }, [eyesGroupElement, rotate])

  React.useEffect(() => {
    if ((eyesLeftElement.current) && (size !== undefined) && (separation !== undefined)) {
      const { x: oX, y: oY, width, height } = eyesLeftElement.current.getBBox()
      const matrixLeft = calculateMatrix(oX, oY, width, height, size)
      setEyesLeftElementTransform(`translate(${-separation}) ${matrixLeft}`)
    }
  }, [eyesLeftElement, size, separation])

  React.useEffect(() => {
    if ((eyesRightElement.current) && (size !== undefined)) {
      const { x: oX, y: oY, width, height } = eyesRightElement.current.getBBox()
      const matrixRight = calculateMatrix(oX, oY, width, height, size)
      setEyesRightElementTransform(`translate(${separation}) ${matrixRight}`)
    }
  }, [eyesRightElement, size, separation])

  return (
    <g transform={`translate(${x}, ${y})`}>
      <g ref={eyesGroupElement} transform={eyesGroupElementTransform}>
        <defs>
          <g id="eye">
            {eyeElement}
          </g>
        </defs>
        <g ref={eyesLeftElement} transform={eyesLeftElementTransform}>
          <use href="#eye" fill={left?.color} />
        </g>
        <g ref={eyesRightElement} transform={eyesRightElementTransform}>
          <use href="#eye" fill={right?.color} />
        </g>
      </g>
    </g>
  )
}

export default Eyes
