import React from 'react'

type Props = {
  id: string,
  label: string,
  value: string,
  onChange: (value: string) => void,
}

function ColorPicker({ id, label, value, onChange }: Props) {
  return (
    <div>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <input
        id={id}
        className="form-control form-control-color mx-auto"
        type="color"
        value={value}
        onChange={event => onChange(event.target.value)}
      />
    </div>
  )
}

export default ColorPicker
