import React from 'react'
import { Link } from 'react-router-dom'

import Carita from './components/Carita'

type Props = {
  theme: string,
}

type CustomLink = {
  label: string,
  url: string,
  type: string,
}

function Landing({ theme }: Props) {
  const links: Array<CustomLink> = [
    { label: 'Foro', url: 'https://foro.showtime.uy', type: 'external' },
    { label: 'Wiki', url: 'https://wiki.showtime.uy', type: 'external' },
    { label: 'Videos', url: '/videos', type: 'internal' },
    { label: 'Caritas', url: '/caritas', type: 'internal' },
  ]

  function linkElement(link: CustomLink) {
    if (link.type === 'internal') {
      return (
        <Link className={`btn d-block ${theme === 'dark' ? 'btn-light' : 'btn-dark'}`} to={link.url}>
          {link.label}
        </Link>
      )
    }

    return (
      <a className={`btn d-block ${theme === 'dark' ? 'btn-light' : 'btn-dark'}`} href={link.url}>
        {link.label}
      </a>
    )
  }

  const linkElements = links.map((link, index) => {
    return (
      <div key={index} className="col-md-3 mb-4">
        {linkElement(link)}
      </div>
    )
  })

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <Carita />
        </div>
      </div>
      <div className="row">
        {linkElements}
      </div>
    </div>
  )
}

export default Landing
