import React from 'react'

function Mouth1() {
  return (
    <g>
      <g>
        <path style={{ fill: '#535353' }} d="M78.1,20.1C78.1,36.6,64.7,50,48.2,50S18.3,36.6,18.3,20.1"/>
        <path style={{ fill: '#000' }} d="M48.2,53.6c-18.5,0-33.5-15-33.5-33.5c0-2,1.6-3.7,3.7-3.7s3.7,1.6,3.7,3.7
          c0,14.5,11.8,26.2,26.2,26.2s26.2-11.8,26.2-26.2c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7C81.7,38.6,66.7,53.6,48.2,53.6z"/>
      </g>
      <g>
        <path style={{ fill: '#000' }} d="M48.3,26.1c-0.2,0-0.4,0-0.6,0C29.5,25.9,12.5,18.7,1,6.1C-0.4,4.7-0.3,2.3,1.2,1
          C2.7-0.4,5-0.3,6.3,1.2c10.2,11,25.3,17.5,41.4,17.6c0.2,0,0.4,0,0.6,0c16.1,0,30.9-6.3,41.8-17.7c1.4-1.5,3.7-1.5,5.2-0.1
          s1.5,3.7,0.1,5.2C83.1,19,66.4,26.1,48.3,26.1z"/>
      </g>
      <g>
        <path style={{ fill: '#EE3424' }} d="M35.5,47.2c-12.2-7.6-7.4-14.9,14.6-14.9c21.9,0,18.4,10.4,11.7,14.5"/>
        <path style={{ fill: '#000' }} d="M35.5,50.8c-0.7,0-1.3-0.2-1.9-0.6c-8.2-5.1-8.9-9.8-8-12.8c1.7-5.9,9.9-8.9,24.5-8.9
          c11.8,0,18.6,2.9,20.2,8.6c1.4,5.1-2.3,10.1-6.6,12.7c-1.7,1-4,0.5-5-1.2s-0.5-4,1.2-5c2.4-1.5,3.6-3.6,3.3-4.5s-3.1-3.2-13.1-3.2
          c-13.4,0-17.2,2.8-17.5,3.6c0,0.3,0.8,2.1,4.8,4.6c1.7,1.1,2.2,3.3,1.2,5C37.9,50.2,36.7,50.8,35.5,50.8z"/>
      </g>
      <g>
        <path style={{ fill: '#000' }} d="M48.2,53.6c-18.5,0-33.5-15-33.5-33.5c0-2,1.6-3.7,3.7-3.7s3.7,1.6,3.7,3.7
          c0,14.5,11.8,26.2,26.2,26.2s26.2-11.8,26.2-26.2c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7C81.7,38.6,66.7,53.6,48.2,53.6z"/>
      </g>
    </g>
  )
}

export default Mouth1