import React from 'react'

import Mouth1 from '../../svgs/mouths/Mouth1'
import Mouth2 from '../../svgs/mouths/Mouth2'
import Mouth3 from '../../svgs/mouths/Mouth3'
import Mouth4 from '../../svgs/mouths/Mouth4'

import { calculateTransform } from '../../utils'

import { Mouth as MouthType } from '../../../../types/Mouth'

type Props = {
  style: MouthType | undefined,
}

function Mouth({ style }: Props) {
  const x = style?.x
  const y = style?.y
  const rotate = style?.rotate
  const size = style?.size
  const option = style?.option
  const mouthGroupElement = React.useRef<SVGGElement>(null)
  const [transform, setTransform] = React.useState('')

  let mouthElement = null
  if (option === '1') {
    mouthElement = <Mouth1 />
  } else if (option === '2') {
    mouthElement = <Mouth2 />
  } else if (option === '3') {
    mouthElement = <Mouth3 />
  } else if (option === '4') {
    mouthElement = <Mouth4 />
  }

  React.useEffect(() => {
    if ((mouthGroupElement.current) && (rotate !== undefined) && (size !== undefined)) {
      const { x: oX, y: oY, width, height } = mouthGroupElement.current.getBBox()
      const transform = calculateTransform(oX, oY, width, height, rotate, size / 100)
      setTransform(`${transform.rotate} ${transform.matrix}`)
    }
  }, [size, rotate, mouthGroupElement])

  return (
    <svg x={x} y={y}>
      <g ref={mouthGroupElement} transform={transform}>
        {mouthElement}
      </g>
    </svg>
  )
}

export default Mouth
