import React from 'react'

function Eyes1() {
  return (
    <g transform="matrix(1.33333 0 0 -1.33333 -475.42 389.42)">
      <path d="M371.87 271.04c0-5.45-1.86-9.88-4.16-9.88s-4.17 4.43-4.17 9.88c0 5.46 1.87 9.88 4.17 9.88s4.16-4.42 4.16-9.88"/>
      <path d="M376.46 278.54a2.39 2.39 0 0 0-2.38 2.38 6.38 6.38 0 0 1-12.75 0 2.39 2.39 0 1 0-4.77 0c0 6.15 5 11.15 11.14 11.15 6.15 0 11.15-5 11.15-11.15a2.39 2.39 0 0 0-2.39-2.38"/>
    </g>
  )
}

export default Eyes1