import React from 'react'

type Props = {
  show: boolean,
}

function Loading({ show }: Props) {
  if (!show) return null

  return (
    <div className="loading">
      <img src="/assets/imgs/carita.svg" alt="" className="carita mb-2" />
      <span className="h1">
        Carganding...
      </span>
    </div>
  )
}

export default Loading
