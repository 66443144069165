import React from 'react'

type Props = {
  color: string | undefined,
  image: string | undefined,
}

function Background({ color, image }: Props) {
  let fill = color
  if (image) {
    fill = 'url(#bg-image)'
  }
  return(
    <g>
      <defs>
        <pattern id="bg-image" patternUnits="userSpaceOnUse" width="100" height="100">
          <image preserveAspectRatio="xMidYMid slice" width="100" height="100" href={image}></image>
        </pattern>
      </defs>
      <circle cx="50" cy="50" r="45" fill={fill} />
    </g>
  )
}

export default Background
