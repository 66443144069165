import React from 'react'

function Eyes4() {
  return (
    <g>
			<g>
				<circle fill="#fff" cx="14.9" cy="30.8" r="10.8"/>
				<path d="M14.9,44.8c-7.7,0-14-6.3-14-14s6.3-14,14-14s14,6.3,14,14S22.6,44.8,14.9,44.8z M14.9,23.1
					c-4.2,0-7.6,3.4-7.6,7.6s3.4,7.6,7.6,7.6s7.6-3.4,7.6-7.6S19.1,23.1,14.9,23.1z"/>
			</g>
			<g>
				<ellipse cx="14.9" cy="30.8" rx="3" ry="2.9"/>
			</g>
		</g>
  )
}

export default Eyes4