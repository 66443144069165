import React from 'react'

function Eyes2() {
  return (
    <g>
			<path d="M30.6,16.7c-1.6,0-2.9-1.3-2.9-2.9c0-4.2-5.9-7.7-12.9-7.7c-7,0-12.9,3.5-12.9,7.7c0,1.6-1.3,2.9-2.9,2.9s-2.9-1.3-2.9-2.9
				c0-7.5,8.2-13.4,18.7-13.4c10.5,0,18.7,5.9,18.7,13.4C33.5,15.4,32.2,16.7,30.6,16.7z"/>
			<path fill="#fff" d="M30.7,23.8c0,8.8-7.1,15.9-15.9,15.9S-1.1,32.6-1.1,23.8H30.7z"/>
			<path d="M14.8,42.6c-10.4,0-18.8-8.4-18.8-18.8c0-1.6,1.3-2.9,2.9-2.9h31.8c1.6,0,2.9,1.3,2.9,2.9C33.6,34.2,25.2,42.6,14.8,42.6z
				M2.1,26.7c1.3,5.8,6.5,10.2,12.7,10.2c6.2,0,11.4-4.4,12.7-10.2H2.1z"/>
			<path d="M19.8,22.4c0,6.6-2.2,11.9-5,11.9s-5-5.3-5-11.9H19.8z"/>
		</g>
  )
}

export default Eyes2