import React from 'react'

function Mouth1() {
  return (
    <g transform="matrix(1.33333 0 0 -1.33333 -420.47 348)">
      <path fill="#fff" d="m379.55 243.16-5.05-6.34-1.42 6.2a1.59 1.59 0 0 1-2.82.6l-3.35-4.4-3.84 4.48c-.3.35-.74.56-1.2.56h-.03a1.6 1.6 0 0 1-1.21-.6l-3.64-4.5-3.78 4.36c-.08.12-.15.25-.27.35-.12.1-.26.16-.4.22-.15.07-.3.1-.45.12a.78.78 0 0 1-.44 0c-.16-.01-.3-.05-.45-.11v-.01c-.15-.06-.28-.12-.4-.22s-.19-.23-.27-.35l-3.78-4.35-3.64 4.5c-.3.36-.75.58-1.22.59-.4 0-.92-.2-1.23-.56l-3.83-4.48-3.36 4.4a1.6 1.6 0 0 1-2.81-.6l-1.42-6.2-5.45 6.84c-.16.2-.37.34-.6.44l-3.04 11.32c-.08.28 0 .51.24.68.12.09.25.13.37.13s.24-.04.37-.13a53.68 53.68 0 0 1 60.74 0c.25.17.5.17.74 0s.32-.4.24-.68z"/>
      <path fill="#d66525" d="M368.44 221.05a33.78 33.78 0 0 0-33.88 0 17.62 17.62 0 0 0-8.02 10.64l-1.39 5.15 3.66-4.58a1.6 1.6 0 0 1 2.79.63L333 239l2.5-3.27a1.59 1.59 0 0 1 2.47-.07l3.87 4.52 3.63-4.49a1.59 1.59 0 0 1 2.43-.04l3.97 4.56 3.96-4.56a1.59 1.59 0 0 1 2.44.04l3.63 4.49 3.87-4.52a1.59 1.59 0 0 1 2.47.07l2.5 3.27 1.4-6.1a1.59 1.59 0 0 1 2.8-.64l2.53 3.19-1.01-3.76a17.62 17.62 0 0 0-8.02-10.64"/>
      <path fill="#000" d="m379.55 243.16-5.05-6.34-1.42 6.2a1.59 1.59 0 0 1-2.82.6l-3.35-4.4-3.84 4.48c-.3.35-.74.56-1.2.56h-.03a1.6 1.6 0 0 1-1.21-.6l-3.64-4.5-3.78 4.36c-.08.12-.15.25-.27.35-.12.1-.26.16-.4.22-.15.07-.3.1-.45.12a.78.78 0 0 1-.44 0c-.16-.01-.3-.05-.45-.11v-.01c-.15-.06-.28-.12-.4-.22s-.19-.23-.27-.35l-3.78-4.35-3.64 4.5c-.3.36-.75.58-1.22.59-.4 0-.92-.2-1.23-.56l-3.83-4.48-3.36 4.4a1.6 1.6 0 0 1-2.81-.6l-1.42-6.2-5.45 6.84c-.16.2-.37.34-.6.44l-3.04 11.32c-.08.28 0 .51.24.68.12.09.25.13.37.13s.24-.04.37-.13a53.68 53.68 0 0 1 60.74 0c.25.17.5.17.74 0s.32-.4.24-.68zm-11.11-22.1a33.78 33.78 0 0 0-33.88 0 17.62 17.62 0 0 0-8.02 10.62l-1.39 5.16 3.66-4.58a1.6 1.6 0 0 1 2.79.63L333 239l2.5-3.27c.28-.38.73-.61 1.21-.63.44-.01.94.2 1.26.56l3.87 4.52 3.63-4.49a1.59 1.59 0 0 1 2.43-.04l3.97 4.56 3.96-4.56a1.6 1.6 0 0 1 2.44.04l3.63 4.49 3.87-4.52c.32-.37.82-.57 1.26-.56.48.02.92.25 1.22.63l2.49 3.27 1.4-6.11a1.59 1.59 0 0 1 2.8-.63l2.53 3.19-1.01-3.76a17.62 17.62 0 0 0-8.02-10.64M385.36 260a5.36 5.36 0 0 1-6.2.03 48.9 48.9 0 0 0-55.32 0 5.36 5.36 0 0 1-6.2-.03 5.36 5.36 0 0 1-2.1-5.83l6.4-23.73a22.42 22.42 0 0 1 10.22-13.51 38.47 38.47 0 0 1 38.68 0 22.43 22.43 0 0 1 10.22 13.51l6.4 23.74c.6 2.21-.23 4.5-2.1 5.82"/>
    </g>
  )
}

export default Mouth1