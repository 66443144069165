import React from 'react'
import { Routes, Route } from "react-router-dom"

import './styles/main.scss'

import Header from './components/Header'

import Landing from './pages/landing/Landing'
import Caritas from './pages/caritas/Caritas'
import Videos from './pages/videos/Videos'

function App() {
  let defaultTheme = 'dark'
  if (
    (window.matchMedia) &&
    (window.matchMedia('(prefers-color-scheme: light)').matches)
  ) {
    defaultTheme = 'light'
  }
  const [theme, setTheme] = React.useState(defaultTheme)

  function enableLightMode() {
    document.body.classList.remove('text-light')
    document.body.classList.remove('bg-dark')
    document.body.classList.add('text-dark')
    document.body.classList.add('bg-light')
  }

  function enableDarkMode() {
    document.body.classList.remove('text-dark')
    document.body.classList.remove('bg-light')
    document.body.classList.add('text-light')
    document.body.classList.add('bg-dark')
  }

  React.useEffect(() => {
    if (theme === 'dark') enableDarkMode()
    else enableLightMode()
  }, [theme])

  return (
    <>
      <Header theme={theme} setTheme={setTheme} />
      <Routes>
        <Route path="/" element={<Landing theme={theme} />} />
        <Route path="/caritas" element={<Caritas theme={theme} />} />
        <Route path="/videos" element={<Videos theme={theme} />} />
      </Routes>
    </>
  )
}

export default App
