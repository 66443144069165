import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  theme: string,
  setTheme: (theme: string) => void,
}

function Header({ theme, setTheme }: Props) {
  function updateTheme() {
    if (theme === 'dark') setTheme('light')
    else setTheme('dark')
  }

  return (
    <nav id="nav" className={`navbar navbar-expand-lg ${theme === 'dark' ? 'navbar-light bg-light' : 'navbar-dark bg-dark'}`}>
      <div className="container">
        <Link className="navbar-brand d-flex" to="/">
          <img src="/logo192.png" alt="" height="30" width="30" className="me-2" />
          Showtime!
        </Link>
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <button id="theme-toggle-btn" type="button" className="btn btn-link" onClick={updateTheme}>
              <img id="light-mode-btn" src="/assets/imgs/sun.svg" alt="Light mode" className={ theme === 'dark' ? '' : 'd-none'} />
              <img id="dark-mode-btn" src="/assets/imgs/moon.svg" alt="Dark mode" className={ theme === 'dark' ? 'd-none' : ''} />
            </button>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header
