function calculateNewCenter(x: number, y: number, width: number, height: number) {
  return { centerX: x + width / 2, centerY: y + height / 2 }
}

function calculateRotate(x: number, y: number, width: number, height: number, deg: number) {
  const { centerX, centerY } = calculateNewCenter(x, y, width, height)
  return `rotate(${deg} ${centerX} ${centerY})`
}

function calculateMatrix(x: number, y: number, width: number, height: number, size: number) {
  const { centerX, centerY } = calculateNewCenter(x, y, width, height)
  const newX = (1 - size) * (centerX - x)
  const newY = (1 - size) * (centerY - y)
  return `matrix(${size}, 0, 0, ${size}, ${newX}, ${newY})`
}

function calculateTransform(x: number, y: number, width: number, height: number, deg: number, size: number) {
  const rotate = calculateRotate(x, y, width, height, deg)
  const matrix = calculateMatrix(x, y, width, height, size)
  return { rotate, matrix }
}

export { calculateTransform, calculateRotate, calculateMatrix }
