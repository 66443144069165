import React, { MouseEventHandler, ChangeEvent } from 'react'

import Range from './formControls/Range'
import ColorPicker from './formControls/ColorPicker'
import Select from './formControls/Select'

import { Values } from '../../../types/Values'

type Props = {
  values: Values,
  onChange: (values: Values) => void,
  defaults: Values,
  onDownload: MouseEventHandler<HTMLButtonElement>,
  theme: string,
}

function FaceControls({ values, onChange, defaults, onDownload, theme }: Props) {
  const [open, setOpen] = React.useState(false)
  const [eyesXPosition, setEyesXPosition] = React.useState(values.eyes.x)
  const [eyesYPosition, setEyesYPosition] = React.useState(values.eyes.y)
  const [eyesSize, setEyesSize] = React.useState(values.eyes.size)
  const [eyesRotate, setEyesRotate] = React.useState(values.eyes.rotate)
  const [eyesSeparation, setEyesSeparation] = React.useState(values.eyes.separation)
  const [eyeOption, setEyeOption] = React.useState(values.eyes.option)
  const [colorLeftEye, setColorLeftEye] = React.useState(values.eyes.left.color)
  const [colorRightEye, setColorRightEye] = React.useState(values.eyes.right.color)
  const [mouthXPosition, setMouthXPosition] = React.useState(values.mouth.x)
  const [mouthYPosition, setMouthYPosition] = React.useState(values.mouth.y)
  const [mouthRotate, setMouthRotate] = React.useState(values.mouth.rotate)
  const [mouthSize, setMouthSize] = React.useState(values.mouth.size)
  const [mouthOption, setMouthOption] = React.useState(values.mouth.option)
  const [width, setWidth] = React.useState(values.face.width)
  const [height, setHeight] = React.useState<number>(values.face.height)
  const [rotate, setRotate] = React.useState(values.face.rotate)
  const [color, setColor] = React.useState(values.face.color)
  const [image, setImage] = React.useState(values.face.image)
  const imageInput = React.useRef<HTMLInputElement>(null)

  const eyeOptions = [
    { label: 'Showtime', value: '1' },
    { label: 'Chill', value: '2' },
    { label: 'Intensos', value: '3' },
    { label: 'Shock', value: '4' },
  ]

  const mouthOptions = [
    { label: 'Showtime', value: '1' },
    { label: 'Kool-Aid', value: '2' },
    { label: 'Pecas', value: '3' },
    { label: 'Wohaa!', value: '4' },
  ]

  React.useEffect(() => {
    onChange({
      face: {
        width: width / 100,
        height: height / 100,
        rotate: rotate,
        color: color,
        image: image,
      },
      eyes: {
        x: eyesXPosition,
        y: eyesYPosition,
        size: eyesSize / 100,
        separation: eyesSeparation,
        rotate: eyesRotate,
        option: eyeOption,
        left: {
          color: colorLeftEye,
        },
        right: {
          color: colorRightEye,
        },
      },
      mouth: {
        x: mouthXPosition,
        y: mouthYPosition,
        size: mouthSize,
        rotate: mouthRotate,
        option: mouthOption,
      },
    })
  }, [
    onChange,
    eyesXPosition,
    eyesYPosition,
    eyesSize,
    eyesSeparation,
    eyesRotate,
    eyeOption,
    colorLeftEye,
    colorRightEye,
    mouthXPosition,
    mouthYPosition,
    mouthSize,
    mouthRotate,
    mouthOption,
    width,
    height,
    rotate,
    color,
    image,
  ])

  function reset() {
    if (imageInput.current) imageInput.current.value = ''
    setEyesXPosition(defaults.eyes.x)
    setEyesYPosition(defaults.eyes.y)
    setEyesSize(defaults.eyes.size)
    setEyesRotate(defaults.eyes.rotate)
    setEyesSeparation(defaults.eyes.separation)
    setEyeOption(defaults.eyes.option)
    setColorLeftEye(defaults.eyes.left.color)
    setColorRightEye(defaults.eyes.right.color)
    setMouthXPosition(defaults.mouth.x)
    setMouthYPosition(defaults.mouth.y)
    setMouthSize(defaults.mouth.size)
    setMouthRotate(defaults.mouth.rotate)
    setMouthOption(defaults.mouth.option)
    setWidth(defaults.face.width)
    setHeight(defaults.face.height)
    setRotate(defaults.face.rotate)
    setColor(defaults.face.color)
    setImage(defaults.face.image)
  }

  function selectColor(color: string) {
    if (imageInput.current) imageInput.current.value = ''
    setImage('')
    setColor(color)
  }

  function processImage(event: ChangeEvent) {
    if (!event.target) return
    const file = (event.target as HTMLInputElement).files?.[0]
    if (!file) return
    const reader = new FileReader()
    reader.onload = () => setImage(reader.result as string)
    reader.readAsDataURL(file)
  }

  return (
    <div className={`face-controls ${open ? 'open' : ''} ${theme === 'light' ? 'face-controls--light' : ''}`} onClick={() => setOpen(false)}>
      <div className="face-controls__wrapper">
        <div className="face-controls__content" onClick={event => event.stopPropagation()}>
          <button
            type="button"
            className="btn btn-info face-controls__close"
            onClick={() => setOpen(false)}
          >
            <img src="/assets/imgs/closeIcon.svg" alt="Cerrar" />
          </button>
          <Range
            id="height"
            label="Alargar"
            min="1"
            max="100"
            value={height}
            onChange={setHeight}
          />
          <Range
            id="width"
            label="Estirar"
            min="1"
            max="100"
            value={width}
            onChange={setWidth}
          />
          <Range
            id="rotate"
            label="Rotar"
            min="-180"
            max="180"
            value={rotate}
            onChange={setRotate}
          />
          <div className="row mb-3">
            <div className="col-2">
              <ColorPicker
                id="color"
                label="Color"
                value={color}
                onChange={selectColor}
              />
            </div>
            <div className="col-10">
              <label className="form-label" htmlFor="image">
                Imagen
              </label>
              <input
                id="image"
                className="form-control"
                type="file"
                ref={imageInput}
                onChange={processImage}
              />
            </div>
          </div>
          <hr />
          <h2>
            Ojos
          </h2>
          <Select
            id="eyes-options"
            label="Diseños"
            value={eyeOption}
            options={eyeOptions}
            onChange={setEyeOption}
          />
          <Range
            id="eyes-y"
            label="Posición vertical"
            min="0"
            max="65"
            value={eyesYPosition}
            onChange={setEyesYPosition}
          />
          <Range
            id="eyes-x"
            label="Posición horizontal"
            min="14"
            max="67"
            value={eyesXPosition}
            onChange={setEyesXPosition}
          />
          <Range
            id="eyes-size"
            label="Tamaño"
            min="10"
            max="100"
            value={eyesSize}
            onChange={setEyesSize}
          />
          <Range
            id="eyes-separation"
            label="Separación"
            min="0"
            max="34"
            value={eyesSeparation}
            onChange={setEyesSeparation}
          />
          <Range
            id="eyes-rotate"
            label="Rotar"
            min="-180"
            max="180"
            value={eyesRotate}
            onChange={setEyesRotate}
          />
          <div className="row mb-3 text-center">
            <div className="col-6">
              <h3>
                Ojo izq.
              </h3>
              <ColorPicker
                id="eyes-left-color"
                label="Color"
                value={colorLeftEye}
                onChange={setColorLeftEye}
              />
            </div>
            <div className="col-6">
              <h3>
                Ojo der.
              </h3>
              <ColorPicker
                id="eyes-right-color"
                label="Color"
                value={colorRightEye}
                onChange={setColorRightEye}
              />
            </div>
          </div>
          <hr />
          <h2>
            Boca
          </h2>
          <Select
            id="mouth-options"
            label="Diseños"
            value={mouthOption}
            options={mouthOptions}
            onChange={setMouthOption}
          />
          <Range
            id="mouth-y"
            label="Posición vertical"
            min="-12"
            max="46"
            value={mouthYPosition}
            onChange={setMouthYPosition}
          />
          <Range
            id="mouth-x"
            label="Posición horizontal"
            min="-17"
            max="21"
            value={mouthXPosition}
            onChange={setMouthXPosition}
          />
          <Range
            id="mouth-size"
            label="Tamaño"
            min="10"
            max="100"
            value={mouthSize}
            onChange={setMouthSize}
          />
          <Range
            id="mouth-rotate"
            label="Rotar"
            min="-180"
            max="180"
            value={mouthRotate}
            onChange={setMouthRotate}
          />
          <hr className="mb-4" />
          <div className="row">
            <div className="col-12 col-md-6">
              <button
                type="button"
                className="btn d-block w-100 btn-secondary mb-4"
                onClick={reset}
              >
                Reiniciar
              </button>
            </div>
            <div className="col-12 col-md-6">
              <button
                type="button"
                className="btn d-block w-100 btn-primary"
                onClick={onDownload}
              >
                Descargar
              </button>
            </div>
          </div>
          <button
            type="button"
            className={`btn btn-info face-controls__toggle ${open ? 'd-none' : ''}`}
            onClick={() => setOpen(true)}
          >
            <img src="/assets/imgs/settingsIcon.svg" alt="Opciones" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default FaceControls
