import React from 'react'

type Props = {
  title: string,
}

function Title({ title }: Props) {
  return (
    <h1 className="text-center mb-4">
      {title}
    </h1>
  )
}

export default Title
